import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { Language } from '@mui/icons-material'

const LanguageSelector = ({ currentLanguage, toggleLanguage, t }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLanguageToggle = () => {
        toggleLanguage()
        handleClose()
    }

    return (
        <>
            <IconButton
                sx={{ color: 'white' }}
                onClick={handleClick}
                aria-label={t.changeLanguage}
                title={t.changeLanguage}
            >
                <Language />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleLanguageToggle} selected={currentLanguage === 'en'}>
                    English
                </MenuItem>
                <MenuItem onClick={handleLanguageToggle} selected={currentLanguage === 'fr'}>
                    Français
                </MenuItem>
            </Menu>
        </>
    )
}

export default LanguageSelector
