import React, { useState, useRef, useEffect } from 'react'
import { Input } from './components/ui/input'
import EnhancedInput from './components/EnhancedInput'
import { Button } from './components/ui/button'
import { Card, CardContent } from './components/ui/card'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import { RadioGroup, RadioGroupItem as RadioGroupItemPrimitive } from './components/ui/radio-group'
import { Label } from './components/ui/label'
import { X, Plus, Settings } from 'lucide-react'
import { Popover, PopoverTrigger, PopoverContent } from './components/ui/popover'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import latex from 'react-syntax-highlighter/dist/esm/languages/hljs/latex'
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import LanguageSelector from './components/LanguageSelector'
import './App.css'

const App = () => {
    const [language, setLanguage] = useState(() => {
        const params = new URLSearchParams(window.location.search)
        const langParam = params.get('lang')
        return langParam === 'fr' ? 'fr' : 'en'
    })

    const [latexCode, setLatexCode] = useState('')
    const [lastAddedNodeId, setLastAddedNodeId] = useState(null)
    const [nodeShape, setNodeShape] = useState('none')
    const [useSnEdges, setUseSnEdges] = useState(true)
    const [nodeFillColor, setNodeFillColor] = useState('')
    const [nodeOutlineColor, setNodeOutlineColor] = useState('black')
    const [edgeColor, setEdgeColor] = useState('black')
    const [fontColor, setFontColor] = useState('black')
    const [edgeWeightColor, setEdgeWeightColor] = useState('black')
    const [treeOrientation, setTreeOrientation] = useState("grow'=0")
    const [levelDistance, setLevelDistance] = useState('2.5cm')
    const [siblingDistance, setSiblingDistance] = useState('1.5cm')
    const [useMathContent, setUseMathContent] = useState(true)
    const [showProbabilityProduct, setShowProbabilityProduct] = useState(false)
    const [nodeOptions, setNodeOptions] = useState({})
    const [nodePathColors, setNodePathColors] = useState({})
    const [nodeRightText, setNodeRightText] = useState({})
    const [optionsPanelOpen, setOptionsPanelOpen] = useState(false)
    const [notification, setNotification] = useState(null)
    const inputRefs = useRef({})
    const [nodes, setNodes] = useState([
        {
            id: '0',
            name: '\\Omega',
            probability: '',
            children: [
                {
                    id: '0-0',
                    name: 'A',
                    probability: '\\frac{1}{2}',
                    children: [
                        { id: '0-0-0', name: 'B', probability: '\\frac{1}{2}', children: [] },
                        { id: '0-0-1', name: '\\overline{B}', probability: '\\frac{1}{2}', children: [] },
                    ],
                },
                {
                    id: '0-1',
                    name: '\\overline{A}',
                    probability: '\\frac{1}{2}',
                    children: [
                        { id: '0-1-0', name: 'B', probability: '\\frac{1}{2}', children: [] },
                        { id: '0-1-1', name: '\\overline{B}', probability: '\\frac{1}{2}', children: [] },
                    ],
                },
            ],
        },
    ])

    useEffect(() => {
        const url = new URL(window.location)
        url.searchParams.set('lang', language)
        window.history.pushState({}, '', url)
    }, [language])

    useEffect(() => {
        if (lastAddedNodeId && inputRefs.current[lastAddedNodeId]) {
            const input = inputRefs.current[lastAddedNodeId]
            input.focus()
            input.select()
            setLastAddedNodeId(null)
        }
    }, [lastAddedNodeId])

    useEffect(() => {
        if (treeOrientation.includes('90') || treeOrientation.includes('270')) {
            setSiblingDistance('3cm')
        } else {
            setSiblingDistance('1.5cm')
        }
    }, [treeOrientation])

    SyntaxHighlighter.registerLanguage('latex', latex)

    const t = (key) => translations[language][key] || key

    const toggleLanguage = () => {
        const newLang = language === 'en' ? 'fr' : 'en'
        setLanguage(newLang)
        const url = new URL(window.location)
        url.searchParams.set('lang', newLang)
        window.history.pushState({}, '', url)
    }

    const findNodeById = (id, node) => {
        if (node.id === id) {
            return node
        }
        for (let child of node.children || []) {
            const found = findNodeById(id, child)
            if (found) {
                return found
            }
        }
        return null
    }

    const addChild = (parentIndex) => {
        const newNodes = [...nodes]
        const path = parentIndex.split('-').map(Number)
        let currentNode = newNodes[path[0]]
        for (let i = 1; i < path.length; i++) {
            currentNode = currentNode.children[path[i]]
        }
        const newNodeId = `${parentIndex}-${currentNode.children.length}`
        currentNode.children.push({
            id: newNodeId,
            name: 'N',
            probability: '\\frac{1}{2}',
            children: [],
        })
        setNodes(newNodes)
        setLastAddedNodeId(newNodeId)
    }

    const updateNode = (index, field, value) => {
        const newNodes = [...nodes]
        const path = index.split('-').map(Number)
        let currentNode = newNodes[path[0]]
        for (let i = 1; i < path.length; i++) {
            currentNode = currentNode.children[path[i]]
        }
        currentNode[field] = value
        setNodes(newNodes)
    }

    const deleteNode = (index) => {
        const newNodes = [...nodes]
        const path = index.split('-').map(Number)
        const nodeToRemove = path.pop()
        let currentNode = newNodes[path[0]]
        for (let i = 1; i < path.length; i++) {
            currentNode = currentNode.children[path[i]]
        }
        currentNode.children.splice(nodeToRemove, 1)
        setNodes(newNodes)
    }

    const updateNodeOption = (nodeId, options) => {
        setNodeOptions((prev) => ({
            ...prev,
            [nodeId]: options,
        }))
    }

    const updateNodePathColor = (nodeId, color) => {
        setNodePathColors((prev) => ({ ...prev, [nodeId]: color }))
        colorPath(nodeId, color)
    }

    const updateNodeRightText = (nodeId, text) => {
        setNodeRightText((prev) => ({
            ...prev,
            [nodeId]: text,
        }))
    }

    const Switch = React.forwardRef(({ className, ...props }, ref) => (
        <SwitchPrimitives.Root
            className={`peer inline-flex h-[24px] w-[44px] ml-2 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-[#3f51b5] data-[state=unchecked]:bg-input ${className}`}
            {...props}
            ref={ref}
        >
            <SwitchPrimitives.Thumb className="pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0" />
        </SwitchPrimitives.Root>
    ))
    Switch.displayName = SwitchPrimitives.Root.displayName

    const RadioGroupItem = React.forwardRef(({ className, ...props }, ref) => (
        <RadioGroupItemPrimitive ref={ref} className={`${className} border-[#3f51b5] text-[#3f51b5]`} {...props} />
    ))

    const downloadTexFile = () => {
        const element = document.createElement('a')
        const file = new Blob([latexCode], { type: 'text/plain' })
        element.href = URL.createObjectURL(file)
        element.download = 'tree.tex'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }

    const colorPath = (nodeId, color) => {
        const newNodePathColors = { ...nodePathColors }
        let currentId = nodeId

        while (currentId.includes('-')) {
            newNodePathColors[currentId] = color
            currentId = currentId.split('-').slice(0, -1).join('-')
        }
        newNodePathColors[currentId] = color
        setNodePathColors(newNodePathColors)
    }

    const getLabelPosition = (orientation) => {
        switch (orientation) {
            case 'grow=0':
            case "grow'=0":
                return 'right, xshift=2em, yshift=-0.75em'
            case 'grow=90':
            case "grow'=90":
                return 'above, yshift=2em'
            case 'grow=180':
            case "grow'=180":
                return 'left, xshift=-2em, yshift=-0.75em'
            case 'grow=270':
            case "grow'=270":
                return 'below, yshift=-2em'
            default:
                return 'right, xshift=2em, yshift=-1em'
        }
    }

    const generateProbabilityProduct = (node, nodes) => {
        if (!node.probability || node.name === ',phantom') return ''

        let path = node.id.split('-')
        let product = []
        let nodeNames = []
        let currentPath = ''

        for (let i = 0; i < path.length; i++) {
            currentPath += (i > 0 ? '-' : '') + path[i]
            let currentNode = findNodeById(currentPath, nodes)
            if (currentNode && currentNode.name !== ',phantom') {
                if (currentNode.probability && currentNode.probability.trim() !== '') {
                    if (currentNode.probability.includes('\\frac')) {
                        product.push(currentNode.probability)
                    } else {
                        product.push(`\\frac{${currentNode.probability}}{1}`)
                    }
                }
                if (i > 0 && currentNode.name && currentNode.name.trim() !== '') {
                    nodeNames.push(currentNode.name)
                }
            }
        }

        if (product.length === 0 || nodeNames.length === 0) return ''

        const intersectionEvents = nodeNames.join('\\cap ')
        return `P(${intersectionEvents})=${product.join(' \\times ')}`
    }

    const generateNodeCode = (node, parentPath = '') => {
        if (!node.name && !node.probability && node.children.length === 0) {
            return '[,phantom]'
        }

        let code = '['
        const options = []

        if (node.name) {
            code += node.name
        }

        const pathColor = nodePathColors[node.id]
        if (node.probability) {
            const escapedProbability = node.probability.replace(',', '{,}')
            if (pathColor) {
                options.push(`w={\\color{${pathColor}}${escapedProbability}}`)
            } else {
                options.push(`w=${escapedProbability}`)
            }
        }

        if (nodeOptions[node.id]) {
            options.push(nodeOptions[node.id])
        }

        if (pathColor) {
            options.push(`edge={draw=${pathColor}}`)
        }

        if (node.children.length === 0 && showProbabilityProduct) {
            const labelPosition = getLabelPosition(treeOrientation)
            const labelText = nodeRightText[node.id] || generateProbabilityProduct(node, nodes[0])
            if (labelText) {
                options.push(`label={[${labelPosition}]$\\displaystyle ${labelText}$}`)
            }
        }

        if (options.length > 0) {
            code += `, ${options.join(', ')}`
        }

        if (node.children && node.children.length > 0) {
            code += '\n' + node.children.map((child) => generateNodeCode(child)).join('') + ']'
        } else {
            code += ']'
        }

        return code
    }

    const generateLatex = () => {
        const generateNodeStyle = () => {
            const style = []
            if (nodeShape !== 'none') {
                style.push(nodeShape)
                style.push(nodeOutlineColor !== 'black' ? `draw=${nodeOutlineColor}` : 'draw')
                if (nodeFillColor) {
                    style.push(`fill=${nodeFillColor}`)
                }
            }
            return style.join(',')
        }

        const nodeStyle = generateNodeStyle()
        const treeOptions = [
            nodeStyle,
            edgeColor !== 'black' ? `edge={draw=${edgeColor}}` : '',
            fontColor !== 'black' ? `text=${fontColor}` : '',
            treeOrientation,
            `l=${levelDistance}`,
            `s sep=${siblingDistance}`,
            'anchor=parent',
            useSnEdges ? 'sn edges' : '',
            useMathContent ? 'math content' : '',
        ]
            .filter((option) => option !== '')
            .join(',\n    ')

        let code = `\\documentclass[border=20pt]{standalone}
\\usepackage{mlmodern}
\\usepackage[T1]{fontenc}
\\usepackage{amsmath}
\\usepackage[upgreek]{frenchmath}
\\usepackage[svgnames,dvipsnames]{xcolor}
\\usepackage{forest}`

        if (useSnEdges) {
            code += `
\\useforestlibrary{linguistics}`
        }

        code += `
\\forestset{
    w/.style = {
        edge label={
            node[midway, fill=white, text=${edgeWeightColor}] {$#1$}
        }
    },
}
\\begin{document}
\\begin{forest}
    for tree={%
    ${treeOptions}
    },
`

        code += generateNodeCode(nodes[0]).trim()
        code += `
\\end{forest}
\\end{document}`

        setLatexCode(code)
    }

    const compileLatex = () => {
        const form = document.createElement('form')
        form.method = 'POST'
        form.action = 'https://texlive.net/cgi-bin/latexcgi'
        form.enctype = 'multipart/form-data'
        form.target = '_blank'

        const addField = (name, value) => {
            const input = document.createElement('input')
            input.type = 'hidden'
            input.name = name
            input.value = value
            form.appendChild(input)
        }

        addField('engine', 'pdflatex')
        addField('filename[]', 'document.tex')
        addField('filecontents[]', latexCode)
        addField('return', 'pdfjs')

        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
    }

    const compileToSVG = () => {
        const form = document.createElement('form')
        form.method = 'POST'
        form.action = 'https://texlive.net/cgi-bin/latexcgi'
        form.enctype = 'multipart/form-data'
        form.target = '_blank'

        const addField = (name, value) => {
            const input = document.createElement('input')
            input.type = 'hidden'
            input.name = name
            input.value = value
            form.appendChild(input)
        }

        addField('engine', 'pdflatex')
        addField('filename[]', 'document.tex')
        addField('filecontents[]', latexCode)
        addField('return', 'make4ht')

        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
    }

    const editInTeXlive = () => {
        const fullLatexCode = latexCode
        const encodedCode = encodeURIComponent(fullLatexCode)
        const texliveUrl = `https://texlive.net/run?${encodedCode}`
        window.open(texliveUrl, '_blank')
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(latexCode).then(
            () => {
                setNotification('Code LaTeX copié dans le presse-papier !')
                setTimeout(() => setNotification(null), 2000)
            },
            (err) => {
                console.error('Could not copy text: ', err)
                setNotification('Failed to copy LaTeX')
                setTimeout(() => setNotification(null), 2000)
            }
        )
    }

    const renderNode = (node, index, depth = 0) => (
        <div key={node.id} className={`node-container ${depth > 0 ? 'child-node' : ''}`}>
            <div className="node-content bg-white shadow-sm border border-gray-200 rounded-lg p-3 mb-2">
                <div className="flex items-center space-x-2 ">
                    <EnhancedInput
                        ref={(el) => (inputRefs.current[node.id] = el)}
                        value={node.name}
                        onChange={(e) => updateNode(index, 'name', e.target.value)}
                        className="w-1/4"
                        placeholder={t('nodeName')}
                    />
                    <div className="input-with-tooltip w-1/4">
                        <EnhancedInput
                            value={node.probability}
                            onChange={(e) => updateNode(index, 'probability', e.target.value)}
                            className="w-full"
                            placeholder={t('weight')}
                        />
                        <span className="tooltip">{t('useDecimalsTooltip')}</span>
                    </div>
                    {node.children.length === 0 && (
                        <EnhancedInput
                            value={
                                nodeRightText[node.id] ||
                                (showProbabilityProduct ? generateProbabilityProduct(node, nodes[0]) : '')
                            }
                            onChange={(e) => updateNodeRightText(node.id, e.target.value)}
                            className="w-1/4"
                            placeholder={t('comment')}
                        />
                    )}
                    <div className="flex space-x-1">
                        <Button
                            /* className="border border-[#3f51b5] bg-[#3f51b5] text-white hover:bg-blue-700 w-8 h-8 p-0 rounded-full" */
                            className="border border-[#5465b7] bg-[#5465b7] text-white hover:bg-blue-800 w-8 h-8 p-0 rounded-full"
                            onClick={() => addChild(index)}
                        >
                            <Plus size={16} />
                        </Button>
                        {depth > 0 && (
                            <Button
                                className="border border-red-600 bg-red-600 hover:bg-red-700 text-white w-8 h-8 p-0 rounded-full"
                                onClick={() => deleteNode(index)}
                            >
                                <X size={16} />
                            </Button>
                        )}
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button className="border border-gray-600 bg-gray-600 text-white hover:bg-gray-700 w-8 h-8 p-0 rounded-full">
                                    <Settings size={16} />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-80">
                                <div className="grid gap-4">
                                    <div className="space-y-2">
                                        <h4 className="font-medium leading-none">{t('customOptions')}</h4>
                                        <p className="text-sm text-muted-foreground">{t('addCustomOptionsForNode')}</p>
                                    </div>
                                    <div className="grid gap-2">
                                        <Label htmlFor={`options-${node.id}`}>{t('customOptions')}</Label>
                                        <Input
                                            id={`options-${node.id}`}
                                            value={nodeOptions[node.id] || ''}
                                            onChange={(e) => updateNodeOption(node.id, e.target.value)}
                                            placeholder="e.g., fill=red, text=blue"
                                        />
                                        <Label htmlFor={`path-color-${node.id}`}>{t('pathColor')}</Label>
                                        <Input
                                            id={`path-color-${node.id}`}
                                            value={nodePathColors[node.id] || ''}
                                            onChange={(e) => {
                                                updateNodePathColor(node.id, e.target.value)
                                                colorPath(node.id, e.target.value)
                                            }}
                                            placeholder="e.g., red, #00FF00"
                                        />
                                    </div>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>
            </div>
            {node.children.length > 0 && (
                <div className="children-container ml-8 pl-4 border-l border-gray-300">
                    {node.children.map((child, childIndex) => renderNode(child, `${index}-${childIndex}`, depth + 1))}
                </div>
            )}
        </div>
    )

    const translations = {
        en: {
            title: 'Tree Generator',
            treeOptions: 'Tree Options',
            buildYourTree: 'Build Your Tree',
            generateLatexCode: 'Generate LaTeX Code',
            generatedLatexCode: 'LaTeX Code',
            copyToClipboard: 'Copy to Clipboard',
            editInTexlive: 'Edit in TeXlive.net',
            compileToPDF: 'Compile to PDF',
            compileToSVG: 'Compile to SVG Image',
            downloadTexFile: 'Download TeX File',
            nodeName: 'Node Name',
            weight: 'Weight',
            comment: 'Comment',
            addChild: 'Add Child',
            delete: 'Delete',
            customOptions: 'Custom Options',
            addCustomOptionsForNode: 'Add custom options for this node.',
            pathColor: 'Path Color',
            nodeShape: 'Node Shape',
            none: 'None',
            circle: 'Circle',
            rectangle: 'Rectangle',
            nodeFillColor: 'Node Fill Color',
            nodeOutlineColor: 'Node Outline Color',
            edgeColor: 'Edge Color',
            edgeWeightColor: 'Edge Weight Color',
            nodeFontColor: 'Node Font Color',
            treeOrientation: 'Tree Orientation',
            rightGrow: 'Right (grow=0)',
            rightSymmetric: "Right Symmetric (grow'=0)",
            downGrow: 'Down (grow=90)',
            downSymmetric: "Down Symmetric (grow'=90)",
            leftGrow: 'Left (grow=180)',
            leftSymmetric: "Left Symmetric (grow'=180)",
            upGrow: 'Up (grow=270)',
            upSymmetric: "Up Symmetric (grow'=270)",
            levelDistance: 'Level Distance',
            siblingDistance: 'Sibling Distance',
            useMathContent: 'Use Math Content',
            showProbabilityProduct: 'Show Probability Product',
            useSnEdges: 'Use SN Edges',
            latexCodeCopied: 'LaTeX code copied to clipboard!',
            useDecimalsTooltip: 'Use . or , for decimals',
        },
        fr: {
            title: "Générateur d'arbres",
            treeOptions: "Options de l'arbre",
            buildYourTree: 'Construisez votre arbre',
            generateLatexCode: 'Générer le code LaTeX',
            generatedLatexCode: 'Code LaTeX',
            copyToClipboard: 'Copier dans le presse-papiers',
            editInTexlive: 'Éditer sur TeXlive.net',
            compileToPDF: 'Compiler en PDF',
            compileToSVG: 'Compiler en Image SVG',
            downloadTexFile: 'Télécharger le fichier TeX',
            nodeName: 'Nom du nœud',
            weight: 'Poids',
            comment: 'Commentaire',
            addChild: 'Ajouter un nœud enfant',
            delete: 'Supprimer',
            customOptions: 'Options personnalisées',
            addCustomOptionsForNode: 'Ajouter des options pour ce nœud.',
            pathColor: 'Couleur du chemin',
            nodeShape: 'Forme du Nœud',
            none: 'Aucune',
            circle: 'Cercle',
            rectangle: 'Rectangle',
            nodeFillColor: 'Couleur de remplissage du nœud',
            nodeOutlineColor: 'Couleur de contour du nœud',
            edgeColor: 'Couleur des arêtes',
            edgeWeightColor: 'Couleur du poids des arêtes',
            nodeFontColor: 'Couleur de police du nœud',
            treeOrientation: "Orientation de l'arbre",
            rightGrow: 'Droite (grow=0)',
            rightSymmetric: "Droite symétrique (grow'=0)",
            downGrow: 'Haut (grow=90)',
            downSymmetric: "Haut symétrique (grow'=90)",
            leftGrow: 'Gauche (grow=180)',
            leftSymmetric: "Gauche symétrique (grow'=180)",
            upGrow: 'Bas (grow=270)',
            upSymmetric: "Bas symétrique (grow'=270)",
            levelDistance: 'Distance entre niveaux',
            siblingDistance: 'Distance entre enfants',
            useMathContent: 'Utiliser le contenu mathématique',
            showProbabilityProduct: 'Afficher le produit des probabilités',
            useSnEdges: "Utiliser l'option SN pour les arêtes",
            latexCodeCopied: 'Code LaTeX copié dans le presse-papiers !',
            useDecimalsTooltip: 'Vous pouvez utiliser le point ou la virgule pour les nombres décimaux',
        },
    }

    return (
        <>
            <div className="app-container">
                <header className="bg-[#3f51b5] shadow-sm">
                    <div className="max-w-8xl mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
                        <div className="w-1/3 flex justify-start">
                            <a
                                href="https://apps.edulatex.xyz"
                                rel="noopener noreferrer"
                                className="text-white hover:text-white transition-colors duration-200"
                            >
                                <FontAwesomeIcon icon={faHome} className="text-lg transform scale-95" />
                            </a>
                        </div>
                        <h1 className="text-white text-xl font-bold w-1/3 text-center">{t('title')}</h1>
                        <div className="w-1/3 flex justify-end">
                            <LanguageSelector currentLanguage={language} toggleLanguage={toggleLanguage} t={t} />
                        </div>
                    </div>
                </header>
                <main className="main-content">
                    <section className="tree-builder">
                        {renderNode(nodes[0], '0')}

                        <section
                            className={`options ${optionsPanelOpen ? 'open' : 'closed'}`}
                            style={{ marginTop: '1.5rem' }}
                        >
                            <div
                                className="options-header bg-white hover:bg-gray-100 border rounded-md border-gray-200 shadow-md transition-colors duration-200 p-4 cursor-pointer"
                                onClick={() => setOptionsPanelOpen(!optionsPanelOpen)}
                            >
                                <h2 className="options-title">{t('treeOptions')}</h2>
                                <div className="options-toggle">
                                    {optionsPanelOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                                </div>
                            </div>
                            {optionsPanelOpen && (
                                <div className="options-content bg-white border border-gray-200 shadow-md">
                                    <div className="option-group">
                                        <Label>{t('nodeShape')}</Label>
                                        <RadioGroup
                                            value={nodeShape}
                                            onValueChange={setNodeShape}
                                            className="flex space-x-4"
                                        >
                                            <div className="flex items-center space-x-2">
                                                <RadioGroupItem value="none" id="none" />
                                                <Label htmlFor="none">{t('none')}</Label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <RadioGroupItem value="circle" id="circle" />
                                                <Label htmlFor="circle">{t('circle')}</Label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <RadioGroupItem value="rectangle" id="rectangle" />
                                                <Label htmlFor="rectangle">{t('rectangle')}</Label>
                                            </div>
                                        </RadioGroup>
                                    </div>
                                    {nodeShape !== 'none' && (
                                        <>
                                            <div className="option-group">
                                                <Label htmlFor="node-fill-color">{t('nodeFillColor')}</Label>
                                                <Input
                                                    id="node-fill-color"
                                                    value={nodeFillColor}
                                                    onChange={(e) => setNodeFillColor(e.target.value)}
                                                    placeholder="e.g., red, blue, LightGreen"
                                                />
                                            </div>
                                            <div className="option-group">
                                                <Label htmlFor="node-outline-color">{t('nodeOutlineColor')}</Label>
                                                <Input
                                                    id="node-outline-color"
                                                    value={nodeOutlineColor}
                                                    onChange={(e) => setNodeOutlineColor(e.target.value)}
                                                    placeholder="e.g., black, NavyBlue"
                                                />
                                            </div>
                                        </>
                                    )}
                                    <div className="option-group">
                                        <Label htmlFor="edge-color">{t('edgeColor')}</Label>
                                        <Input
                                            id="edge-color"
                                            value={edgeColor}
                                            onChange={(e) => setEdgeColor(e.target.value)}
                                            placeholder="e.g., gray, DarkGreen"
                                        />
                                    </div>
                                    <div className="option-group">
                                        <Label htmlFor="edge-weight-color">{t('edgeWeightColor')}</Label>
                                        <Input
                                            id="edge-weight-color"
                                            value={edgeWeightColor}
                                            onChange={(e) => setEdgeWeightColor(e.target.value)}
                                            placeholder="e.g., black, DarkRed"
                                        />
                                    </div>
                                    <div className="option-group">
                                        <Label htmlFor="font-color">{t('nodeFontColor')}</Label>
                                        <Input
                                            id="font-color"
                                            value={fontColor}
                                            onChange={(e) => setFontColor(e.target.value)}
                                            placeholder="e.g., black, DarkBlue"
                                        />
                                    </div>
                                    <div className="option-group">
                                        <Label htmlFor="tree-orientation">{t('treeOrientation')}</Label>
                                        <select
                                            id="tree-orientation"
                                            value={treeOrientation}
                                            onChange={(e) => setTreeOrientation(e.target.value)}
                                            className="select"
                                        >
                                            <option value="grow=0">{t('rightGrow')}</option>
                                            <option value="grow'=0">{t('rightSymmetric')}</option>
                                            <option value="grow=90">{t('upGrow')}</option>
                                            <option value="grow'=90">{t('upSymmetric')}</option>
                                            <option value="grow=180">{t('leftGrow')}</option>
                                            <option value="grow'=180">{t('leftSymmetric')}</option>
                                            <option value="grow=270">{t('downGrow')}</option>
                                            <option value="grow'=270">{t('downSymmetric')}</option>
                                        </select>
                                    </div>
                                    <div className="option-group">
                                        <Label htmlFor="level-distance">{t('levelDistance')}</Label>
                                        <Input
                                            id="level-distance"
                                            value={levelDistance}
                                            onChange={(e) => setLevelDistance(e.target.value)}
                                            placeholder="e.g., 2.5cm"
                                        />
                                    </div>
                                    <div className="option-group">
                                        <Label htmlFor="sibling-distance">{t('siblingDistance')}</Label>
                                        <Input
                                            id="sibling-distance"
                                            value={siblingDistance}
                                            onChange={(e) => setSiblingDistance(e.target.value)}
                                            placeholder="e.g., 1.5cm"
                                        />
                                    </div>
                                    <div className="option-group">
                                        <Label htmlFor="use-math-content">{t('useMathContent')}</Label>
                                        <Switch
                                            id="use-math-content"
                                            checked={useMathContent}
                                            onCheckedChange={setUseMathContent}
                                        />
                                    </div>
                                    <div className="option-group">
                                        <Label htmlFor="show-probability-product">{t('showProbabilityProduct')}</Label>
                                        <Switch
                                            id="show-probability-product"
                                            checked={showProbabilityProduct}
                                            onCheckedChange={setShowProbabilityProduct}
                                        />
                                    </div>
                                    <div className="option-group">
                                        <Label htmlFor="sn-edges">{t('useSnEdges')}</Label>
                                        <Switch id="sn-edges" checked={useSnEdges} onCheckedChange={setUseSnEdges} />
                                    </div>
                                </div>
                            )}
                        </section>

                        <div className="button-group" style={{ marginTop: '1.5rem' }}>
                            <Button className="bg-[#3f51b5] text-white hover:bg-[#303f9f]" onClick={generateLatex}>
                                {t('generateLatexCode')}
                            </Button>
                        </div>
                    </section>

                    {latexCode && (
                        <section className="latex-output">
                            {/* <h2>{t('generatedLatexCode')}</h2> */}
                            <Card className="bg-white shadow-md">
                                <CardContent className="p-4">
                                    <div className="relative">
                                        <SyntaxHighlighter
                                            language="latex"
                                            style={tomorrow}
                                            customStyle={{
                                                backgroundColor: '#ffffff',
                                                padding: '1rem',
                                                borderRadius: '0.5rem',
                                                fontSize: '0.875rem',
                                                lineHeight: '1.5',
                                                maxHeight: '400px',
                                                overflowY: 'auto',
                                            }}
                                        >
                                            {latexCode}
                                        </SyntaxHighlighter>
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 mt-4">
                                        <Button
                                            className="w-full text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 shadow-sm"
                                            onClick={copyToClipboard}
                                        >
                                            {t('copyToClipboard')}
                                        </Button>
                                        <Button
                                            className="w-full text-white bg-[#4caf50] hover:bg-[#45a049]"
                                            onClick={editInTeXlive}
                                        >
                                            {t('editInTexlive')}
                                        </Button>
                                        <Button
                                            className="w-full bg-[#3f51b5] text-white hover:bg-[#303f9f]"
                                            onClick={compileLatex}
                                        >
                                            {t('compileToPDF')}
                                        </Button>
                                        <Button
                                            className="w-full text-white bg-[#4caf50] hover:bg-[#45a049]"
                                            onClick={compileToSVG}
                                        >
                                            {t('compileToSVG')}
                                        </Button>
                                        <Button
                                            className="w-full text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 shadow-sm"
                                            onClick={downloadTexFile}
                                        >
                                            {t('downloadTexFile')}
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </section>
                    )}

                    {notification && (
                        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-200 text-green-900 py-2 px-4 rounded-md shadow-lg notification">
                            {notification}
                        </div>
                    )}
                </main>
            </div>
        </>
    )
}

export default App
