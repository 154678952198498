import React, { forwardRef } from 'react'

const EnhancedInput = forwardRef(({ className, ...props }, ref) => {
    const handleFocus = (event) => {
        event.target.select()
    }

    return (
        <input
            ref={ref}
            className={`text-sm p-2 text-gray-600 bg-[#eceef6] rounded-md border border-[#ffffff] ${className}`}
            onFocus={handleFocus}
            {...props}
        />
    )
})

export default EnhancedInput
